/**
 * Created by SamWhisker on 26/07/15.
 */
var loader = {
    init: function(){
        $('body').prepend('<div id="progress_bar" style="margin-top:1px;position: fixed;z-index: 999;width:100%;"><span style="height: 3px;width:0px;background: #2973ff;display: block;"></span></div>')
    },
    destroy:function(){
        $('#progress_bar').remove();
    },
    progress:function(int){
        $('#progress_bar span').css('width',int+'%')
    }
}
