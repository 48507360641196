/**
 * Created by SamWhisker on 26/07/15.
 */
var doesExist = {
    id:function(byID)
    {
        if ($('#'+byID).length > 0){
            return true;
        }
        return false;
    },
    class:function(byID)
    {
        if ($('.'+byID).length > 0){
            return true;
        }
        return false;
    }
}